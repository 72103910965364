.payslipViewer {
  display: flex;
  height: 100%;
  align-items: center;

  .birthdayInput {
    border: solid #efefef 1px;
    padding: 10px;
    border-radius: 4px;
  }

  .fileUpload {
    height: 50px;
    box-sizing: border-box;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    border: solid #efefef 1px;
    padding: 10px;
    border-radius: 4px;
    position: relative;

    svg {
      font-size: 18px;
      margin-right: 8px;
    }

    input {
      display: none;
    }
  }
}
