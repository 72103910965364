.app {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .notMobileMessage {
    font-size: 24px;
    display: flex;
  }
}
